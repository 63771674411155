import React from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import Layout from "../components/layout";
import Examples from "../components/sections/_examples-section";
import {componentMap, DataProvider} from "../contentful/data-provider";
import ComponentsSection from "../components/sections/_components-section";
import ogimage from "../images/ogimages/develop.png";

export default function Develop({data}) {
    const dataProvider = new DataProvider(data);
    return (
        <Layout>
            <Helmet
                meta={[
                    {
                        property: `og:title`,
                        content: 'Agoric - Develop',
                    },
                    {
                        property: `twitter:title`,
                        content: 'Agoric - Develop',
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        property: `twitter:image`,
                        content: ogimage,
                    },
                    {
                        property: `og:image`,
                        content: ogimage,
                    },
                    {
                        property: `twitter:card`,
                        content: `summary_large_image`,
                    },
                ]}
            >
                <title>Agoric - Develop</title>
            </Helmet>
            <div className={'developers'}>
                { dataProvider.getCurrentPageSections().map((section, index) => {
                    if (index === 3) {
                        return (
                            <>
                                <ComponentsSection />
                                { dataProvider.getElementById(section.id) &&
                                    React.createElement(
                                        componentMap[section.type],
                                        {content: dataProvider.getElementById(section.id)}
                                    )}
                            </>
                        )
                    }

                    if (index === 5) {
                        return (
                            <>
                                <Examples/>
                                { dataProvider.getElementById(section.id) &&
                                    React.createElement(
                                        componentMap[section.type],
                                        {content: dataProvider.getElementById(section.id)}
                                    )}
                            </>
                        )
                    }

                    return (
                        <>
                            { dataProvider.getElementById(section.id) &&
                                React.createElement(
                                    componentMap[section.type],
                                    {content: dataProvider.getElementById(section.id)}
                                )}
                        </>
                    );
                }) }
            </div>
        </Layout>
    );
}

export const query = graphql`
    query {
        contentfulPage(id: {eq: "ac0aae75-24e5-56d7-bf44-44fc05815b54"}) {
            ... CurrentPageDetails
        }
        allContentfulEntry {
            edges {
                node {
                    ... AllEntryDetails
                }
            }
        }
    }
`;
